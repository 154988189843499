const configs = {
    development: {
      apiUrl: 'http://localhost:8080/mock-mono/v1',
    },
    production: {
      apiUrl: 'https://api.mock4tech.com/mock-mono/v1',
    },
    test: {
      apiUrl: 'https://api.mock4tech.com/mock-mono/v1',
    }
  };
  
  // Get the current environment (default to development if not set)
  const currentEnv = process.env.NODE_ENV || 'development';
  
  // Export the config for the current environment
  export default configs[currentEnv];
  