import React, { useState } from "react";
import "./dashboard.css"; // Importar estilos
import AppHeader from "../../components/AppHeader/AppHeader";

export default function Dashboard() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [certificationDetails, setCertificationDetails] = useState({});

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDetails = (index) => {
    setCertificationDetails((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
        <AppHeader/>
      {/* Menu Responsivo */}
      {/* <nav className="navbar">
        <div className="logo">Mock4Tech</div>
        <div className="menu">
          <a href="#">Perfil</a>
          <a href="#">Simulados</a>
          <a href="#">Configurações</a>
          <a href="#">Amigos</a>
          <a href="#">Notificações</a>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </div>
        <div className={`menu-mobile ${menuOpen ? "open" : ""}`} id="menu-mobile">
          <a href="#">Perfil</a>
          <a href="#">Simulados</a>
          <a href="#">Configurações</a>
          <a href="#">Amigos</a>
          <a href="#">Notificações</a>
        </div>
      </nav> */}

      <div className="profile-container">
        {/* Cabeçalho do Perfil */}
        <div className="profile-header">
          <div className="user-info">
            <img src="https://via.placeholder.com/80" alt="Foto do Usuário" />
            <div className="user-details">
              <h2>João Silva</h2>
              <p>Engenheiro de Software e Entusiasta em Cybersecurity</p>
            </div>
          </div>
          <div className="total-score">
            <i className="fas fa-star"></i>
            <span>Pontuação Total: 1234</span>
          </div>
          <div className="notifications">
            <i className="fas fa-bell"></i>
            <div className="notifications-badge">5</div>
          </div>
          <button className="edit-btn">Editar Perfil</button>
        </div>

        {/* Seção de Ações Rápidas */}
        <div className="quick-actions">
          <div className="action-btn">
            <i className="fas fa-bullseye"></i>
            <p>Criar uma Meta</p>
          </div>
          <div className="action-btn">
            <i className="fas fa-play-circle"></i>
            <p>Fazer um Simulado Agora</p>
          </div>
        </div>

        {/* Seção de Estatísticas */}
        <div className="profile-section">
          <h3>Estatísticas de Desempenho</h3>
          <div className="statistics">
            <div className="stat-card">
              <h4>Desempenho em Certificações</h4>
              <p>Visualize seu progresso em cada certificação.</p>
              <div className="certifications-list">
                {[
                  {
                    name: "AWS Cloud Practitioner",
                    progress: 75,
                    score: 85,
                    attempts: 4,
                    lastAttempt: "01/09/2024",
                    scores: "85%, 80%, 90%, 85%",
                    time: "1m 20s",
                  },
                  {
                    name: "DevOps Engineer",
                    progress: 60,
                    score: 78,
                    attempts: 3,
                    lastAttempt: "15/08/2024",
                    scores: "70%, 80%, 75%",
                    time: "1m 45s",
                  },
                ].map((cert, index) => (
                  <div className="certification-item" key={index}>
                    <div className="certification-summary">
                      <span>Certificação: <strong>{cert.name}</strong></span>
                      <span>Progresso: <progress value={cert.progress} max="100"></progress> {cert.progress}%</span>
                      <span>Pontuação Média: <strong>{cert.score}%</strong></span>
                      <span>Tentativas: <strong>{cert.attempts}</strong></span>
                      <button className="toggle-details" onClick={() => toggleDetails(index)}>
                        Detalhes
                      </button>
                    </div>
                    {certificationDetails[index] && (
                      <div className="certification-details">
                        <p><strong>Última Tentativa:</strong> {cert.lastAttempt}</p>
                        <p><strong>Notas:</strong> {cert.scores}</p>
                        <p><strong>Tempo Médio por Questão:</strong> {cert.time}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="stat-card">
              <h4>Média de Acertos</h4>
              <p>Compare sua média de acertos com a média global.</p>
              <div className="chart">Gráfico de Comparação (Em Desenvolvimento)</div>
            </div>
            <div className="stat-card">
              <h4>Tempo Médio por Questão</h4>
              <p>Analise o tempo gasto em cada tipo de questão.</p>
              <div className="chart">Gráfico de Tempo (Em Desenvolvimento)</div>
            </div>
          </div>
        </div>

        {/* Histórico de Atividades */}
        <div className="profile-section">
          <h3>Histórico de Atividades</h3>
          <div className="activity-log">
            <div className="activity-item">
              <h4>Simulado de DevOps</h4>
              <p>Pontuação: 85% | Tempo: 40 min</p>
            </div>
            <div className="activity-item">
              <h4>Simulado de Python Avançado</h4>
              <p>Pontuação: 75% | Tempo: 35 min</p>
            </div>
            <div className="activity-item">
              <h4>Simulado de AWS Cloud</h4>
              <p>Pontuação: 90% | Tempo: 50 min</p>
            </div>
          </div>
        </div>

        {/* Convite de Amigos */}
        <div className="profile-section">
          <h3>Convide Amigos</h3>
          <div className="friend-options">
            <div className="friend-option">
              <h4>Desafiar um Amigo</h4>
              <p>Convide seus amigos para competir com você em um desafio de simulado!</p>
              <button className="invite-btn">Desafiar Amigo</button>
            </div>
            <div className="friend-option">
              <h4>Convidar para um Simulado</h4>
              <p>Envie um convite para seus amigos fazerem um simulado juntos!</p>
              <button className="invite-btn">Convidar para Simulado</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
