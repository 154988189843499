import './ExamQuizzes.css';
import { FaArrowLeft } from 'react-icons/fa'; // FontAwesome icon
import { FaFilter } from 'react-icons/fa'; // Filter icon
import { SimuladoCard } from './SimuladoCard';
import React, { useEffect, useState } from 'react';
import config from '../../config.js';
import { useLoading } from "../../LoadingContext";
import { Dropdown, ButtonGroup } from 'react-bootstrap'; // Import Bootstrap components
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../utils/getApiLanguageCode.js';

const apiUrl = config.apiUrl;

function App() {
  const { t } = useTranslation('examQuizzes');
  const [quizzes, setQuizzes] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const { loading, setLoading } = useLoading();
  const params = useParams();

  const [questionCount, setQuestionCount] = useState(0);
  const [difficulty, setDifficulty] = useState('ALL');
  const languageCode = getApiLanguageCode(i18n.language);

  const fetchQuizzes = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}/quizes/list?language=${languageCode}&diff=${difficulty}&totalQuestions=${questionCount}&examId=${params.id}`
      );
      const data = await response.json();
      setQuizzes(data.quizes);
      setFilteredQuizzes(data.quizes);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuizzes();
  }, [questionCount, difficulty, languageCode]);

  const difficultySwitch = (difficulty) => {
    return t(`difficulty_levels.${difficulty}`);
  };

  return (
    <div>
      <div className="containerData">
        <h2 className="headerText text-bold text-center">
          {t('available_quizzes')}
        </h2>

        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-4 gap-3">
          <Dropdown as={ButtonGroup} className="w-100 w-md-auto mb-3 mb-md-0 mt-2">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-question-count" className="w-100">
              <b>{t('filter')}:</b> {questionCount === 0 ? t('all') : questionCount} {t('questions')}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {[0, 5, 10, 15, 30, 65].map((count) => (
                <Dropdown.Item 
                  key={count}
                  onClick={() => setQuestionCount(count)} 
                  className={questionCount === count ? 'active-item' : ''}
                >
                  {count === 0 ? t('all') : count}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown as={ButtonGroup} className="w-100 w-md-auto mb-3 mb-md-0 mt-2">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-difficulty" className="w-100">
              <b>{t('filter')}:</b> {t('difficulty')} - {difficultySwitch(difficulty)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {['ALL', 'INTRO', 'MEDIUM', 'HARD', 'VERY_HARD', 'EXPERT'].map((level) => (
                <Dropdown.Item 
                  key={level}
                  onClick={() => setDifficulty(level)}
                  className={difficulty === level ? 'active-item' : ''}
                >
                  {difficultySwitch(level)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {loading ? (
          <p className="text-center text-bold">{t('loading_quizzes')}</p>
        ) : (
          <div className="card-container">
            {filteredQuizzes.length > 0 ? (
              filteredQuizzes.map((quiz) => (
                <SimuladoCard
                  key={quiz.id}
                  quizId={quiz.id}
                  certificationName={quiz.certificationName}
                  totalPeople={quiz.totalPeople}
                  imageUrl={quiz.img}
                  subtitle={quiz.name}
                  levelClass={quiz.level}
                  level={quiz.levelDescription}
                  description={quiz.shortDescription}
                />
              ))
            ) : (
              <p className="text-center text-bold">{t('no_quizzes_found')}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
