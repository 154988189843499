import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import './BlogArticle.css'; // Importar o CSS para o componente
import AppHeader from '../../components/AppHeader/AppHeader';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer/Footer';

const AdSpace = ({ imageUrl }) => (
  <div className="ad-space">
    <p>Publicidade</p>
    <img src={imageUrl} alt="Anúncio" />
  </div>
);

const Article = () => {
  const { t } = useTranslation();

  return (
    <div className="article-container">
      <header className="article-header">
        <h1>{t('article.title')}</h1>
        <p className="article-meta">{t('article.meta')}</p>
      </header>

      <div className="reader-info">
        <p><i className="bi bi-eye"></i> {t('article.readers')}</p>
        <p><i className="bi bi-clock"></i> {t('article.timeToRead')}</p>
      </div>

      <AdSpace imageUrl="https://via.placeholder.com/728x90" />

      <div className="summary-box">
        <strong>{t('article.summary')}</strong>
      </div>
      
      {/* Other article content */}
      <RelatedArticles/>
      <CommentsSection/>
    </div>
  );
};

const RelatedArticles = () => {
  const { t } = useTranslation();

  return (
    <div className="related-articles">
      <h3>{t('relatedArticles.title')}</h3>
      <div className="row g-4">
        <div className="col-md-4">
          <div className="card">
            <img src="https://via.placeholder.com/350x200" className="card-img-top" alt={t('relatedArticles.article1.title')} />
            <div className="card-body">
              <h5 className="card-title">{t('relatedArticles.article1.title')}</h5>
              <p className="card-text">{t('relatedArticles.article1.text')}</p>
              <a href="#" className="btn btn-custom">{t('relatedArticles.readMore')}</a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <img src="https://via.placeholder.com/350x200" className="card-img-top" alt={t('relatedArticles.article2.title')} />
            <div className="card-body">
              <h5 className="card-title">{t('relatedArticles.article2.title')}</h5>
              <p className="card-text">{t('relatedArticles.article2.text')}</p>
              <a href="#" className="btn btn-custom">{t('relatedArticles.readMore')}</a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <img src="https://via.placeholder.com/350x200" className="card-img-top" alt={t('relatedArticles.article3.title')} />
            <div className="card-body">
              <h5 className="card-title">{t('relatedArticles.article3.title')}</h5>
              <p className="card-text">{t('relatedArticles.article3.text')}</p>
              <a href="#" className="btn btn-custom">{t('relatedArticles.readMore')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const CommentsSection = () => {
  const { t } = useTranslation();

  return (
    <div className="comment-section">
      <h3>{t('comments.title')}</h3>
      <form className="comment-form">
        <div className="mb-3">
          <textarea className="form-control" rows="4" placeholder={t('comments.placeholder')}></textarea>
        </div>
        <button type="submit" className="btn btn-custom">{t('comments.submit')}</button>
      </form>
    </div>
  );
};


const ArticlePage = () => (
  <div>
    <AppHeader />
    <Article />
    <Footer />
  </div>
);

export default ArticlePage;
