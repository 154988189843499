import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SplashScreen.css';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const SplashScreen = () => {
  const [gifSrc, setGifSrc] = useState('/mock4tech-animation-web.gif');
  const { t } = useTranslation('splashScreen');

  useEffect(() => {
    // Detecta se é um dispositivo móvel ou desktop
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    if (isMobile) {
      setGifSrc('/mock4tech-animation.gif');
    } else {
      setGifSrc('/mock4tech-animation.gif');
    }
  }, []);

  return (
    <div className="splash-screen d-flex flex-column justify-content-center align-items-center">
      <img
        src={gifSrc}
        alt="Carregando..."
        className="mock4tech-animation"
      />
      <div className="loading-spinner mt-3">
        <Spinner animation="border" role="status" variant="success" />
        <span className="visually-hidden">{t('loading')}</span>
      </div>
      <p className="loading-text mt-2">{t('loading')}</p>
    </div>
  );
};

export default SplashScreen;
