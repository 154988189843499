import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation('footer');  // Use the 'navigation' namespace

  return (
    <footer className="bg-dark text-white py-4">
      <div className="container text-center">
        {/* <h4>{t('footerSubscribe')}</h4>
        <form className="d-flex justify-content-center">
          <input
            type="email"
            placeholder={t('footerEmailPlaceholder')}
            required
            className="form-control"
            style={{ maxWidth: '300px', borderRadius: '20px 0 0 20px' }}
          />
          <button
            type="submit"
            className="btn btn-success"
            style={{ borderRadius: '0 20px 20px 0' }}
          >
            {t('footerSubscribeButton')}
          </button>
        </form> */}
        <div className="social-icons mt-3">
          <a href="https://www.linkedin.com/company/mock4tech" target="_blank" className="text-success me-3">
            <i className="bi bi-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/@Mock4Tech" target="_blank" className="text-success me-3">
            <i className="bi bi-youtube"></i>
          </a>
          <a href="https://www.instagram.com/mock4tech" target="_blank" className="text-success">
            <i className="bi bi-instagram"></i>
          </a>
        </div>
        <p className="mt-3">{t('footerRights')}</p>
      </div>
    </footer>
  );
};

export default Footer;
