// src/components/NotFoundPage.js
import React, { useEffect, useState } from 'react';
import './NotFound.css';

const NotFoundPage = () => {
  const [suggestions, setSuggestions] = useState([]);

  // Simulados populares (mocked)
  const simuladosPopulares = [
    { nome: "Simulado AWS Certified Solutions Architect", url: "/simulados/aws-solutions-architect" },
    { nome: "Simulado AWS Certified Developer", url: "/simulados/aws-developer" },
    { nome: "Simulado AWS Certified SysOps Administrator", url: "/simulados/aws-sysops" }
  ];

  // Certificações novas (mocked)
  const novasCertificacoesAWS = [
    { nome: "AWS Certified Database - Specialty", url: "/certificacoes/aws-database-specialty" },
    { nome: "AWS Certified Machine Learning - Specialty", url: "/certificacoes/aws-ml-specialty" },
    { nome: "AWS Certified Security - Specialty", url: "/certificacoes/aws-security-specialty" }
  ];

  useEffect(() => {
    // Junta simulados e certificações
    setSuggestions([...simuladosPopulares, ...novasCertificacoesAWS]);
  }, []);

  return (
    <div className="nfBody">
        <div className="notFound-container">
        <i className="bi bi-exclamation-triangle-fill text-danger" style={{ fontSize: 80 }}></i>
        <h1 className="text-danger">Oops! Página não encontrada</h1>
        <p>Infelizmente, não conseguimos encontrar a página que você procura. Mas temos ótimos conteúdos que podem ser do seu interesse!</p>

        {/* Sugestões de navegação
        <div className="suggestions">
            <h2>Explore nossos simulados e certificações:</h2>
            <div className="nfcards">
            {suggestions.map((item, index) => (
                <div key={index} className="nfcard">
                <a href={item.url}>{item.nome}</a>
                </div>
            ))}
            </div>
        </div> */}

        {/* Destaque que é Free e sem custo
        <div className="free-banner">
            Totalmente Free! Não há nenhum custo para começar.
        </div>

        <div className="features">
            <div className="feature-item">
            <img src="https://img.icons8.com/external-flat-juicy-fish/60/10b981/external-quiz-education-flat-flat-juicy-fish.png" alt="Crie seu próprio simulado" />
            <h3>Crie seu próprio simulado</h3>
            <p>Monte simulados com o conteúdo que você precisa estudar.</p>
            </div>

            <div className="feature-item">
            <img src="https://img.icons8.com/external-flat-juicy-fish/60/10b981/external-personalization-business-flat-flat-juicy-fish.png" alt="Simulados Personalizados" />
            <h3>Simulados personalizados</h3>
            <p>Adapte simulados ao seu nível de conhecimento.</p>
            </div>

            <div className="feature-item">
            <img src="https://img.icons8.com/color/60/10b981/quiz.png" alt="Faça quantos quizes quiser" />
            <h3>Faça quantos quizes quiser</h3>
            <p>Pratique quantos quizes quiser para maximizar seu aprendizado.</p>
            </div>

            <div className="feature-item">
            <img src="https://img.icons8.com/color/60/10b981/challenge.png" alt="Desafie-se" />
            <h3>Desafie e se desafie</h3>
            <p>Testes de desafios diários para manter-se motivado.</p>
            </div>

            <div className="feature-item">
            <img src="https://img.icons8.com/color/60/10b981/trophy.png" alt="Seja um Mock Champion" />
            <h3>Seja um Mock Champion</h3>
            <p>Ganhe prêmios e destaque-se entre os melhores!</p>
            </div>
        </div> */}

        <a href="/" className="home-link">Voltar para a Página Inicial</a>
        </div>
    </div>
  );
};

export default NotFoundPage;
