import './QuizByExam.css'; // Importação do CSS principal
import config from '../../config.js';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Button, Modal, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';

const apiUrl = config.apiUrl;

const QuizCard = ({ finished, certificationName, examName, totalPeople, totalQuestions, domin, service, people }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const startQuiz = () => {
    navigate(`/quiz/0?direction=next`);
  };

  return (
    <div className="quiz-card shadow-sm rounded p-3 bg-white mb-4 h-100 d-flex flex-column justify-content-between">
      <div className="quiz-header mb-3">
        <i className="bi bi-trophy-fill text-warning me-2" style={{ fontSize: 30 }}></i>
        <h4 className="d-inline text-center">{certificationName}</h4>
      </div>
      <p>{t('quizLabel')}: {examName}</p>
      <div className="button-group mt-auto">
        <p className="people-count text-secondary text-center"><i className="bi bi-people-fill me-2"></i>{t('peopleCompleted', { total: totalPeople })}</p>
        <div className="d-grid gap-2 mt-3">
          <Button 
            variant="primary"
            onClick={startQuiz}
            disabled={finished}    
            className="w-100 btn-lg">
              {t('startQuiz')}
          </Button>
          <Button
            variant="outline-secondary"
            onClick={openModal}
            className="w-100 btn-lg">
              {t('viewDetails')}
          </Button>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title><i className="bi bi-info-circle text-success"></i> {t('quizDetails')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
          <p><strong>{t('totalQuestions')}:</strong> {totalQuestions}</p>
          <p><strong>{t('domains')}:</strong> {domin}</p>
          <p><strong>{t('servicesInvolved')}:</strong> {service}</p>
          <p><strong>{t('completedBy', { total: totalPeople })}</strong></p>
          <div className="top-performers mt-4">
            <h5 className="text-center mb-3"><i className="bi bi-trophy-fill text-warning me-2"></i>{t('topPerformers', { count: people.length })}</h5>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>{t('position')}</th>
                  <th>{t('name')}</th>
                  <th>{t('score')}</th>
                </tr>
              </thead>
              <tbody>
                {people.map((person, index) => (
                  <tr key={index}>
                    <td>{person.position}</td>
                    <td>{person.name}</td>
                    <td>{person.score}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

function App() {
  const [exam, setExam] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  // let authToken = searchParams.get('token');
  const [authToken, setAuthToken] = useState(() => {
    const saved = localStorage.getItem("token");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/quizes/list/${authToken}`);
        const data = await response.json();
        setExam(data);      
      } catch (error) {
        console.error('Error fetching the exams:', error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [navigate, authToken]);

  if (loading) {
    return (
      <div className="loading-spinner d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="App my-5">
      <div className="provider-section bg-light p-4 rounded shadow-sm">
        <h3 className="text-primary mb-4 text-center">{exam.examTitle}</h3>
        <hr />
        <Row>
          {exam.quizes && exam.quizes.map((qz) => (
            <Col key={qz.id} xs={12} md={6} lg={4}>
              <QuizCard
                finished={exam.finished}
                certificationName={qz.certificationName}
                examName={qz.name}
                totalPeople={qz.totalPeople}
                totalQuestions={qz.totalQuestions}
                domin={qz.domin}
                service={qz.service}
                people={qz.people}
              />
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
}

export default App;
