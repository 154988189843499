import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import config from '../../config.js';
import { useLoading } from "../../LoadingContext";
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from '../../components/Footer/Footer.js';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

const apiUrl = config.apiUrl;

const ContactPage = () => {
    // State to handle form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        type: '',
        message: ''
    });

    // State to handle success and error alerts
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [questionType, setQuestionType]= useState('');
    const { loading, setLoading } = useLoading();
    const { t } = useTranslation('contact'); // Use the 'home' namespace

    // const [captchaToken, setCaptchaToken] = useState(null);

    // Handle change in input fields
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    // const handleCaptchaChange = (token) => {
    //     setCaptchaToken(token); // Save the token received from reCAPTCHA
    // };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // if (!captchaToken) {
        //     setErrorMessage('Por favor, preencha o CAPTCHA.');
        //     return;
        // }
      
        // Prepare the data to send to the API
        const requestData = {
            email: formData.email,
            description: formData.message,
            name: formData.name,
            typeContact: convertTypeToNumber(questionType)
        };

        try {
            setLoading(true);
            // Clear previous alerts
            setSuccessMessage(false);
            setErrorMessage('');

            const response = await fetch(`${apiUrl}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            if (response.ok) {
                setSuccessMessage(true); // Show success message
                setFormData({ name: '', email: '', type: '', message: '' }); // Clear form after submission
                // setCaptchaToken(null);
            } else {
                setErrorMessage('Erro ao enviar a mensagem. Por favor, tente novamente.');
            }
        } catch (error) {
            setErrorMessage('Erro ao enviar a mensagem. Por favor, verifique sua conexão e tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    // Helper function to convert type to a number
    const convertTypeToNumber = (type) => {
        switch (type) {
            case 'general':
                return 1;
            case 'technical':
                return 2;
            case 'billing':
                return 3;
            case 'other':
                return 4;
            default:
                return 0;
        }
    };

    return (
        <>
            <div className="contact-container container py-5">
                {/* Header Section */}
                <header className="contact-header text-center mb-4">
                    <h1 className="text-primary">{t('header')}</h1>
                    <p>{t('description')}</p>
                </header>

                {/* Success Alert */}
                {successMessage && (
                    <div className="alert alert-success text-center align-items-center text-center" role="alert">
                        <i className="bi bi-check-circle-fill me-2"></i>
                        {t('successMessage')}
                    </div>
                )}

                {/* Error Alert */}
                {errorMessage && (
                    <div className="alert alert-danger text-center align-items-center" role="alert">
                        <i className="bi bi-exclamation-triangle-fill me-2"></i>
                        {errorMessage}
                    </div>
                )}

                {/* Contact Form */}
                <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: '600px' }}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">{t('nameLabel')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder={t('namePlaceholder')}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">{t('emailLabel')}</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder={t('emailPlaceholder')}
                            required
                        />
                    </div>
                    <div className="mb-3" >

                        <Dropdown align="center" onChange={handleChange} className="w-100 w-md-auto mb-3 mb-md-0 mt-2">
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-question-count" className="w-100">
                                {t('typeLabel')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                                <Dropdown.Item key="general" onClick={() => setQuestionType("general")} className={questionType === "general" ? 'active-item' : ''}>
                                    {t('typeOptions.general')}
                                </Dropdown.Item>
                                <Dropdown.Item key="technical" onClick={() => setQuestionType("technical")} className={questionType === "technical" ? 'active-item' : ''}>
                                    {t('typeOptions.technical')}
                                </Dropdown.Item>
                                <Dropdown.Item key="billing" onClick={() => setQuestionType("billing")} className={questionType === "billing" ? 'active-item' : ''}>
                                    {t('typeOptions.billing')}
                                </Dropdown.Item>
                                <Dropdown.Item key="other" onClick={() => setQuestionType("other")} className={questionType === "other" ? 'active-item' : ''}>
                                    {t('typeOptions.other')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="form-label">{t('messageLabel')}</label>
                        <textarea
                            className="form-control"
                            id="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder={t('messagePlaceholder')}
                            required
                        ></textarea>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-success w-100 btn-lg" disabled={loading}>
                            {loading ? t('loadingButton') : t('submitButton')}
                        </button>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );

};

export default ContactPage;
