import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import "./FeedbackForm.css";

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    rating: '',
    comment: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRatingChange = (rating) => {
    setFormData({
      ...formData,
      rating: rating
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode enviar os dados para uma API ou backend
    console.log('Dados enviados:', formData);
    setSubmitted(true);
    setFormData({ name: '', email: '', rating: '', comment: '' }); // Resetando o formulário após envio
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <h3 className="text-center text-primary mb-4">Envie seu Feedback</h3>
              {submitted && (
                <Alert variant="success" onClose={() => setSubmitted(false)} dismissible>
                  Obrigado pelo seu feedback!
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label className="formLabel">Nome</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Digite seu nome"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Digite seu e-mail"
                    required
                  />
                </Form.Group>

                {/* Rating Section */}
                <Form.Group controlId="formRating" className="mb-3">
                  <Form.Label>Avaliação</Form.Label>
                  <div className="d-flex justify-content-between">
                    {['Muito Ruim', 'Ruim', 'Médio', 'Bom', 'Muito Bom'].map((label, index) => (
                      <Button
                        key={index}
                        variant={formData.rating === label ? 'primary' : 'outline-primary'}
                        onClick={() => handleRatingChange(label)}
                        className="me-1"
                      >
                        {index + 1} ⭐
                      </Button>
                    ))}
                  </div>
                </Form.Group>

                {/* Conditionally Render the Comment Field */}
                {(formData.rating === 'Muito Ruim' || formData.rating === 'Ruim') && (
                  <Form.Group controlId="formComment" className="mb-3">
                    <Form.Label>Comentário</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="comment"
                      value={formData.comment}
                      onChange={handleChange}
                      rows={4}
                      placeholder="Escreva seu comentário"
                      required
                    />
                  </Form.Group>
                )}

                <Button variant="primary" type="submit" className="w-100">
                  Enviar Feedback
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FeedbackForm;
