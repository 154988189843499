import React from 'react';
import { useTranslation } from 'react-i18next';
import './CertificationDetails.css';

const StartQuizButton = ({ onClick }) => {
  const { t } = useTranslation('certificationDetails');

  return (
    <button
      className="btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100 start-quiz-btn"
      onClick={onClick}
      style={{fontsize:30, fontWeight:'bold'}}
    >
      <i className="bi bi-play-circle-fill me-2"></i>
      {t('certificationDetails.ctaQuiz')}
    </button>
  );
};

export default StartQuizButton;
