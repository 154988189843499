import React, { useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import Footer from '../../components/Footer/Footer';
import "./Blog.css";
import { useTranslation } from 'react-i18next';

// FeaturedSection Component (Carousel)
const FeaturedSection = () => {
  const { t } = useTranslation();  // Hook to get translations

  return (
    <section className="featured-section">
      <div className="container">
        <div id="featuredCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="https://via.placeholder.com/1200x350" className="d-block w-100" alt={t('featuredSectionAlt1')} />
            </div>
            <div className="carousel-item">
              <img src="https://via.placeholder.com/1200x350" className="d-block w-100" alt={t('featuredSectionAlt2')} />
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#featuredCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">{t('previous')}</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#featuredCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">{t('next')}</span>
          </button>
        </div>
      </div>
    </section>
  );
};

// FilterBar Component
const FilterBar = ({ onFilter }) => {
  const { t } = useTranslation();

  return (
    <div className="container mt-4">
      <div className="filter-bar d-flex justify-content-between align-items-center">
        <div>
          <button className="filter-btn btn btn-success" onClick={() => onFilter('artigos')}>{t('filterBarArticles')}</button>
          <button className="filter-btn btn btn-success" onClick={() => onFilter('videos')}>{t('filterBarVideos')}</button>
          <button className="filter-btn btn btn-success" onClick={() => onFilter('podcasts')}>{t('filterBarPodcasts')}</button>
        </div>
        <input type="text" className="form-control search-bar" placeholder={t('searchPlaceholder')} />
      </div>
    </div>
  );
};

// ContentCard Component
const ContentCard = ({ image, title, description, author, date, buttonLabel }) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-4 content-item">
      <div className="card content-card h-100">
        <img src={image} className="card-img-top" alt={title} />
        <div className="card-body">
          <h5 className="card-title text-success">{title}</h5>
          <p className="card-text">{description}</p>
          <small className="text-muted">{t('by')} {author} | {date}</small>
          <a href="/blog/1" className="btn btn-custom btn-success mt-2">{buttonLabel}</a>
        </div>
      </div>
    </div>
  );
};

// ContentList Component
const ContentList = ({ content }) => {
  const { t } = useTranslation();

  return (
    <div className="container mt-4">
      <div className="row g-4">
        {content.length > 0 ? (
          content.map((item, index) => (
            <ContentCard
              key={index}
              image={item.image}
              title={item.title}
              description={item.description}
              author={item.author}
              date={item.date}
              buttonLabel={item.buttonLabel}
            />
          ))
        ) : (
          <div className="text-center">{t('noContentFound')}</div>
        )}
      </div>
    </div>
  );
};

const App = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('all');

  const contentData = [
    {
      type: 'artigos',
      image: 'https://via.placeholder.com/350x200',
      title: t('articleTitle1'),
      description: t('articleDesc1'),
      author: t('articleAuthor1'),
      date: '01 de Janeiro, 2024',
      buttonLabel: t('readMore'),
    },
    {
      type: 'videos',
      image: 'https://via.placeholder.com/350x200',
      title: t('videoTitle2'),
      description: t('videoDesc2'),
      author: t('videoAuthor2'),
      date: '15 de Fevereiro, 2024',
      buttonLabel: t('watch'),
    },
    {
      type: 'podcasts',
      image: 'https://via.placeholder.com/350x200',
      title: t('podcastTitle3'),
      description: t('podcastDesc3'),
      author: t('podcastAuthor3'),
      date: '10 de Março, 2024',
      buttonLabel: t('listenNow'),
    },
  ];

  const filteredContent = filter === 'all' ? contentData : contentData.filter(item => item.type === filter);

  return (
    <div>
      <FilterBar onFilter={setFilter} />
      <ContentList content={filteredContent} />
      <Footer />
    </div>
  );
};

export default App;
