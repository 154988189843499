import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaStar, FaLinkedin, FaInstagram } from 'react-icons/fa';
import config from '../../config.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../utils/getApiLanguageCode.js';

const apiUrl = config.apiUrl;

const StarRatingModal = ({ show, handleClose, authToken }) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation('starRatingModal');

  const handleSubmit = async () => {
    if (!rating) {
      setErrorMessage(t('selectStarsAlert'));
      return;
    }

    const feedbackData = {
      starCount: rating,
      description: comment || '',
      token: authToken 
    };

    try {
      const response = await fetch(`${config.apiUrl}/feedbacks`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        console.log(t('feedbackSuccess'));
        handleClose();
      } else {
        console.error(t('feedbackError'));
        setErrorMessage(t('feedbackError'));
      }
    } catch (error) {
      console.error(t( 'connectionError'), error);
      setErrorMessage(t('connectionError'));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">{t('modalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <div className="alert alert-danger text-center align-items-center" role="alert">
          <i className="bi bi-exclamation-triangle-fill me-2"></i>
            {errorMessage}
          </div>
        )}

        <div className="d-flex justify-content-center mb-3">
          {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => setRating(ratingValue)}
                  style={{ display: 'none' }}
                />
                <FaStar
                  className="star"
                  color={ratingValue <= (hover || rating) ? '#6c757d' : '#e4e5e9'}
                  size={30}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
        <Form>
          <Form.Group controlId="comment">
            <Form.Label>{t('commentLabel')}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={t('commentPlaceholder')}
            />
          </Form.Group>
        </Form>
        <h6 className="text-center text-bold text-primary mt-3">{t.followUs}</h6>
        <div className="d-flex justify-content-center">
          <a href="https://www.linkedin.com/company/mock4tech/posts/?feedView=all" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FaLinkedin size={50} color="#0077b5" />
          </a>
          <a href="https://www.instagram.com/mock4tech/" target="_blank" rel="noopener noreferrer" className="social-icon ml-3">
            <FaInstagram size={50} color="#E1306C" />
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('closeButton') }
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {t('submitButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default StarRatingModal;
