import React, { useState, useEffect } from "react";
import './ContadorEstudantes.css'; // Para o estilo global
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../utils/getApiLanguageCode.js';
import { FaUserGraduate } from "react-icons/fa"; // Ícone de estudante

const ContadorEstudantes = ({totalPeople}) => {
  // Estado para o contador de questões
  const [contadorQuestoes, setContadorQuestoes] = useState(0);
  const { t } = useTranslation('contadorEstudantes');
  const languageCode = getApiLanguageCode(i18n.language);

  // Limites e parâmetros do contador
  const limiteQuestoes = totalPeople; // Número máximo de estudantes
  const duracaoTotal = 500; // Duração total em milissegundos (5 segundos)
  const incremento = 1; // O contador será incrementado de 10 em 10
  const totalIncrementos = limiteQuestoes / incremento; // Número total de incrementos
  const intervaloQuestoes = duracaoTotal / totalIncrementos; // Intervalo entre os incrementos

  useEffect(() => {
    // Função de atualização do contador
    const atualizarContadorQuestoes = () => {
      setContadorQuestoes((prev) => {
        if (prev < limiteQuestoes) {
          return prev + incremento;
        }
        return prev;
      });
    };

    // Iniciar o contador a cada intervalo
    const intervalo = setInterval(atualizarContadorQuestoes, intervaloQuestoes);

    // Limpar intervalo ao desmontar o componente
    return () => clearInterval(intervalo);
  }, [intervaloQuestoes, limiteQuestoes]);

  return (
    <a href="#" className="botao-contador">
      <FaUserGraduate style={{ marginRight: "15px", fontSize: "24px" }} />
      <span>+{contadorQuestoes.toLocaleString("pt-BR")}</span>&nbsp;{t('students')}
    </a>
  );
};

export default ContadorEstudantes;