import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppHeader from './components/AppHeader/AppHeader.js';
import { Container } from 'semantic-ui-react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { LoadingProvider } from './LoadingContext';
import i18n from './i18n';



i18n.init().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <LoadingProvider>
        <AppHeader/>
        <App />
      </LoadingProvider>
    </React.StrictMode>
);
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
