import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Modal, Tab, Tabs, Accordion } from 'react-bootstrap';
import './QuizResult.css';
import React, { useState, useEffect, startTransition, lazy, Suspense } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLoading } from "../../LoadingContext";
import config from '../../config.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getApiLanguageCode } from '../../utils/getApiLanguageCode.js';
const EmailModal = lazy(() => import('./EmailModal.js'));
const apiUrl = config.apiUrl;


const urlRegex = /(https?:\/\/[^\s]+)/g;
const HighlightedText = ({ text }) => {
  const parts = text.split(urlRegex);
  return (
    <p>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <a 
            key={index} 
            href={part} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {part}
          </a>
        ) : (
          part
        )
      )}
    </p>
  );
};

const QuizReview = () => {
  const { t } = useTranslation("quizReview");  // useTranslation hook
  const languageCode = getApiLanguageCode(i18n.language);

  const [quizResults, setQuizResults] = useState({});
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [selectedExplanation, setSelectedExplanation] = useState('');
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [searchParams] = useSearchParams();
  const { loading, setLoading } = useLoading();
  const direction = searchParams.get('direction');
  const [authToken, setAuthToken] = useState(() => {
    const saved = localStorage.getItem("token");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [newsletterSubmited, setNewsletterSubmited] = useState(() => {
    const saved = localStorage.getItem("newsletterSubmited");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [showEmailModal, setShowEmailModal] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const currentQuestionId = params.questionId;

  const fetchAnswer = async (token, currentUserQuestionId, direction) => {
    const url = `${apiUrl}/user-quiz/question-result?token=${token}&currentUserQuestionId=${currentUserQuestionId}&direction=${direction}&language=${languageCode}`;
    const options = { method: 'GET' };

    try {
      setLoading(true);
      const response = await fetch(url, options);
      const data = await response.json();
      console.log("data:" + JSON.stringify(data));
      // Update quizResults state with data from the API
      setQuizResults(
        {
          id: data.idUserQuizQuestion,
          examId: data.examId,
          type: data.type,
          questionNumber: data.questionNumber,
          totalQuestion: data.totalQuestion,
          question: data.questionName,
          timeTaken: data.responseTime,
          difficulty: data.level,
          questionId: data.idUserQuizQuestion,
          category: data.category,
          domain: data.domains,
          subdomain: data.subDomins,
          firstOne: data.firstOne,
          lastOne: data.lastOne,
          services: data.services,
          options: data.responses.map((response) => response),
          explanations: data.responses.map((response) => response.description)
        }
      );
      console.log(quizResults);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnswer(authToken, currentQuestionId, direction);
    
    // Wrap EmailModal visibility in startTransition
    startTransition(() => {
      setShowEmailModal(true);
    });

  }, [authToken, currentQuestionId, direction]);

  const handleShowExplanation = (optionIndex, answerText) => {
    setSelectedExplanation(quizResults.explanations[optionIndex]);
    setSelectedAnswer(answerText);
    setShowExplanationModal(true);
  };

  const handleNextQuestion = () => {
    navigate(`/quiz-result/${parseInt(quizResults.questionId)}?direction=next`);
  };

  const handlePreviousQuestion = () => {
    navigate(`/quiz-result/${parseInt(quizResults.questionId)}?direction=previous`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedExplanation)
      .then(() => {})
      .catch(err => console.error('Failed to copy:', err));
  };

  return (
    <> 
      <div className="review-container container mt-5">
        <h2 className="text-center text-primary">{t('quizReview.title')}</h2>

        <div className="time-card mt-3">
          <a id="submitComment" className="btn btn-danger w-100 text-bold text-center" href={`/exam-quizzes/${quizResults.examId}`}>
            <i className="bi bi-check-circle"></i> {t('quizReview.finishReview')}
          </a>
        </div>

        <h5 className="text-left text-primary mt-3">
          {t('quizReview.question')} {quizResults.questionNumber} {t('quizReview.of')} {quizResults.totalQuestion}
        </h5>

        <h4 className="question mb-3 mt-3">{quizResults.question}</h4>

        <Tabs defaultActiveKey="question" id="questionTabs" className="mt-3 mb-3">
          <Tab eventKey="question" title={t('quizReview.questionTab')}>
            <div className="options-container mt-3">
              {quizResults.options && quizResults.options.map((option, index) => (
                <div
                  onClick={() => handleShowExplanation(index, option.name)}
                  key={index}
                  className={`option ${option.corrected ? 'correct' : 'incorrect'}`}
                  style={{ cursor: 'pointer', marginBottom: '10px', padding: '10px', border: '1px solid #ddd' }}
                >
                  {quizResults.type === "SINGLE" && (
                    <input type="radio" name={`question-${index}`} value={option.id} checked={option.checked} style={{ marginRight: '10px' }} />
                  )}
                  {quizResults.type === "MULTI" && (
                    <input id={`option-${option.id}`} type="checkbox" name={`question-${index}`} value={option.id} checked={option.checked} style={{ marginRight: '10px' }} />
                  )}
                  <label className="optionResponse">{option.name}</label>
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>

        <div className="d-flex justify-content-between row mt-4">
          <div className="col-6">
            <button className="btn btn-secondary w-100 btn-lg" onClick={handlePreviousQuestion} disabled={quizResults.firstOne}>
              {t('quizReview.previous')}
            </button>
          </div>
          <div className="col-6">
            <button className="btn btn-primary w-100 btn-lg" onClick={handleNextQuestion} disabled={quizResults.lastOne}>
              {t('quizReview.next')}
            </button>
          </div>
        </div>

        <Modal className="custom-modal" show={showExplanationModal} onHide={() => setShowExplanationModal(false)}>
          <Modal.Body>
            <div id="selectedAnswer" className={`selected-answer ${selectedAnswer.includes(t('quizReview.explanation.correct')) ? 'correct' : 'incorrect'}`}>
              {selectedAnswer}
            </div>
            <div id="modalExplanationContent" className="mt-3">
              {/* Explanation text component goes here */}
              <HighlightedText text={selectedExplanation} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={() => setShowExplanationModal(false)}>
              {t('quizReview.close')}
            </button>
            <button onClick={handleCopy} className="btn btn-success">
              {t('quizReview.copyContent')}
            </button>
          </Modal.Footer>
        </Modal>
      
        {/* <Suspense fallback={<div>Loading...</div>}>
          {!newsletterSubmited && <EmailModal show={showEmailModal} handleClose={() => setShowEmailModal(false)} />}
        </Suspense> */}
      </div>
    </>
  );
};

export default QuizReview;
